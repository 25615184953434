import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import Modal from "react-modal";
import Confetti from "react-confetti";

const data = [
    {
      image: {
        uri: "/blue-headphones-isolated-white-background.png",
        sizeMultiplier: 1.5,
        offsetX: 20,
      },
      style: "w-42 h-42",
    },
    {
      image: {
        uri: "/rb_7793.png",
        sizeMultiplier: 1.5,
        offsetX: 20,
      },
      style: "w-48 h-48",
    },
    {
      image: {
        uri: "/blue-headphones-isolated-white-background.png",
        sizeMultiplier: 1.5,
        offsetX: 20,
      },
      style: "w-48 h-48",
    },
    {
      image: {
        uri: "/mobile.png",
        sizeMultiplier: 1.5,
        offsetX: 20,
      },
      style: "w-48 h-48",
    },
  ];
  
Modal.setAppElement("#root");

const LandingPage = () => {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [showModal, setShowModal] = useState(false);
  
    const handleSpinClick = () => {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    };
  
   
  
    const closeModal = () => {
      setShowModal(false);
    };
  

  return (
    <div className="bg-gradient-to-br from-purple-600 to-red-500 min-h-screen">
      {/* Hero Section */}
      <header className="text-center py-10">
        <h1 className="text-5xl font-extrabold text-white">
          🎉 Welcome to the Ultimate Spinoria Challenge! 🎉
        </h1>
        <p className="mt-4 text-lg text-gray-200">
          Spin the wheel for a chance to win exciting prizes. <br />
          Don't miss out on the fun—your next surprise awaits!
        </p>
      </header>

      {/* How It Works */}
      <section className="text-center py-10 bg-white">
        <h2 className="text-3xl font-bold text-purple-600">How It Works</h2>
        <div className="grid grid-cols-3 gap-6 max-w-5xl mx-auto mt-8">
          <div className="p-8 bg-purple-50 rounded-lg shadow-lg">
            <img
              src="/Layer 11[1].png"
              alt="Spin the Wheel"
              className="mx-auto mb-4"
            />
            <p className="text-gray-700 font-medium">Spin the wheel for free.</p>
          </div>
          <div className="p-6 bg-purple-50 rounded-lg shadow-lg">
            <img
              src="https://img.icons8.com/color/96/gift.png"
              alt="Win a Prize"
              className="mx-auto mb-4"
            />
            <p className="text-gray-700 font-medium">
              Land on a surprise reward.
            </p>
          </div>
          <div className="p-6 bg-purple-50 rounded-lg shadow-lg">
            <img
              src="https://img.icons8.com/color/96/whatsapp.png"
              alt="Claim Your Reward"
              className="mx-auto mb-4"
            />
            <p className="text-gray-700 font-medium">
              Follow the steps to claim your gift.
            </p>
          </div>
        </div>
      </section>

      {/* Spinner Section */}
      <div className="bg-gradient-to-r from-purple-500 to-red-500">
      <div className="wheel-container flex items-center justify-center h-screen relative">
        <h1 className="text-5xl text-center absolute z-30 top-10 font-bold text-white left-[42vw]">
          Spin & Win
        </h1>
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          outerBorderColor={"yellow"}
          outerBorderWidth={10}
          innerBorderColor={"transparent"}
          radiusLineColor={"#fff"}
          radiusLineWidth={1}
          textColors={["#fff"]}
          textDistance={60}
          fontSize={[18]}
          fontWeight={[500]}
          startingOptionIndex={0}
          backgroundColors={[
            "#165FA9",
            "#239b63",
            "#F7A415",
            "#3F297E",
            "#BE1080",
            "#DC0836",
          ]}
          onStopSpinning={() => {
            setMustSpin(false);
            setShowModal(true);
          }}
          className="relative z-10"
        />
        <button
          className="spin-button w-20 h-20 border-2 bg-red-500  rounded-full text-white font-bold absolute z-20"
          onClick={handleSpinClick}
        >
          SPIN
        </button>

        {showModal && (
          <>
            <Confetti width={window.innerWidth} height={window.innerHeight} />
            <Modal
              isOpen={showModal}
              onRequestClose={closeModal}
              className="modal-content bg-white rounded-lg shadow-lg p-6 text-center relative z-30"
              overlayClassName="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20"
            >
              <h2 className="text-2xl font-bold text-green-500 mb-4">
                🎉 Choose Your Gift! 🎉
              </h2>
              <div className="grid grid-cols-2 gap-4">
                {Array.from({ length: 4 }).map((_, index) => (
                  <div
                    key={index}
                    className="gift-box p-4 shadow-2xl rounded-md cursor-pointer hover:scale-105 transition-transform"
                  >
                    <img
                     onClick={() => window.open("https://wa.link/ggnow", "_blank")}
                      src="https://img.freepik.com/free-psd/birthday-colorful-present-box-design_23-2150318126.jpg"
                      alt="Gift Box"
                      className="w-28  h-28 mx-auto"
                    />
                  </div>
                ))}
              </div>
              <button
                onClick={closeModal}
                className="mt-6 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
              >
                Close
              </button>
            </Modal>
          </>
        )}
      </div>
    </div>
      {/* Benefits Section */}
      <section className="text-center py-10 bg-white">
        <h2 className="text-3xl font-bold text-purple-600">Why Spin & Win?</h2>
        <p className="mt-4 text-lg text-gray-600 max-w-3xl mx-auto">
          Our Spin & Win challenge is designed to bring excitement, joy, and
          rewards to your day. Every spin is a chance to win exclusive surprises
          crafted just for you.
        </p>
      </section>

      {/* Footer */}
      <footer className="text-center py-4 bg-purple-800 text-white">
        <p>&copy; 2024 Spinoria. All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
